
<template>
    <div class="modal" :class="{'is-active': $parent.show_tj_comments_modal}">
        <div class="modal-background" @click.self="$parent.show_tj_comments_modal = false"></div>
        <div class="modal-card">
            <section class="modal-card-body custom_scrollbar-1 p-0">
                <div class="content is-flex-direction-column mb-0 p-2 has-background-white-ter">
                    <div class="is-flex is-justify-space-between">
                        <div>
                            <i class="is-block has-text-grey">
                                {{$parent.getTrucksName(this.$parent.$parent.selected_truck_job.details.truck_id, $parent.$parent.warehouses_parameters)}}
                            </i>
                        </div>
                        <a @click.prevent="show_create_new = !show_create_new, new_comment.content = ''" class="button is-small ml-auto mb-2">
                            {{$t("add_comment")}} 
                            <i v-if="!show_create_new" class="fas fa-plus fa-sm ml-2"></i>
                            <i v-else class="fas fa-minus fa-sm ml-2"></i>
                        </a>
                    </div>
                </div>
                <div v-if="show_create_new" class="is-flex is-justify-content-space-between p-2">
                    <div class="" style="width:100%" >
                        <div style="position:relative;">
                            <textarea v-model="new_comment.content" rows="2" class="textarea is-small" :placeholder="$t('create_comment')"></textarea>
                            <button @click.prevent="show_create_new = !show_create_new, new_comment.content = ''" style="position:absolute; top:5px; right:5px;" class="delete is-small"></button>
                        </div>
                    </div>
                    <div class="is-align-self-flex-end is-flex is-justify-content-center" style="flex-shrink:8; width:100%;">
                        <a @click.prevent="save_note(new_comment)" href="" class="button is-small">{{$t("create_comment")}}</a>
                    </div>
                </div>
                <div class="content is-flex-direction-column p-2">
                    <div v-for="(comment, index) in tj_comments_list" :key="index"
                         class="notification has-background-white is-light px-0 p-2 mb-1 has-border">
                        <p class="is-size-7 mb-0" >
                            <span class="has-text-grey">
                                <i class="fa fa-user fa-sm has-text-grey-lighter"></i> {{comment.created_by_name}} 
                                <small class="has-text-grey">
                                    <i class="fa fa-calendar fa-sm has-text-grey-lighter"></i> {{comment.updated_at != null ? comment.updated_at : comment.created_at | datetime }}
                                </small>
                            </span>
                            <span class="is-pulled-right">
                                <a v-if="comment.created_by == $parent.$parent.$parent.user.IdUser" @click.prevent="delete_note(comment)" href="" class="ml-2">
                                    <i class="fas fa-trash-alt has-text-danger" style="opacity: 50%;" ></i>
                                </a>
                                <a v-if="comment.created_by == $parent.$parent.$parent.user.IdUser" @click.prevent="switch_edit_note(comment)" href="" class="ml-2" >
                                    <i class="fas fa-edit has-text-info" style="opacity: 50%;"></i>
                                </a>
                            </span>
                        </p>
                        <div>
                            <div class="is-flex is-flex-direction-column" v-if="comment.edit_mode" >
                                <div style="position:relative;">
                                    <textarea v-model="comment.content" rows="2" class="textarea is-small" placeholder="Create comment"></textarea>
                                    <button @click.prevent="cancel_edit_comment(comment)" style="position:absolute; top:5px; right:5px;" class="delete is-small"></button>
                                </div>
                                <a @click.prevent="save_note(comment)" href="" class="button mt-2 is-small ml-auto">{{$t("save")}}</a>
                            </div>
                            <span v-else class="has-text-black">
                                {{comment.content}}
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

    <script>
import axios from 'axios'
import socketMixin from '@/socket-mixin.js'
import socket from '@/socket.js'
export default {
    mixins: [ socketMixin ],
    data() {
        return {
            new_comment: {},
            show_create_new: false,
            tj_comments_list: []
        }
    },
    created(){
        this.get_tj_comments()
    },
    mounted(){
        socket.on('someone said', this.socket_listener)
    },
    beforeDestroy() {
		socket.off("someone said", this.socket_listener)
	},
    methods: {
        socket_listener(data){
            if(data.type == 'add note'){
                if(data.data[0].truck_job_id == this.$parent.$parent.selected_truck_job.guid)
                    this.tj_comments_list.unshift(data.data[0])
            }
            else if(data.type == 'delete note'){
                this.tj_comments_list = this.tj_comments_list.filter(n => n.guid != data.data)
            }
            else if(data.type == 'edit note'){
                this.tj_comments_list = [...this.tj_comments_list.map(n => {
                    if(n.guid == data.data.guid){
                        n = data.data
                        n.edit_mode = false
                    }
                    return n
                })]
            }
        },
        get_tj_comments(){
            axios.post(this.$tetris_server + '/get/tj/comment', {
                truck_job: this.$parent.$parent.selected_truck_job
            }).then(r => {
                this.tj_comments_list = r.data.map(res => {
                    res.edit_mode = false
                    res.original_content = res.content
                    return res
                })
            })
        },
        save_note(c){
            let operation = 0
            c.guid ? operation = 2 : operation = 1, // 1-create, 2-edit

            axios.post(this.$tetris_server + '/save/tj/comment', {
                comment: c,
                truck_job: this.$parent.$parent.selected_truck_job,
                user: JSON.parse(localStorage.user)
            })
            .then(res => {

                if(res.data == 'empty_content'){ // If content empty
                    this.$toast('empty_content', 'error')
                } else {
                    if(operation == 1){
                        // create
                        var data = res.data[0]
                        data.original_content = res.data[0].content
                        this.tj_comments_list.unshift(res.data[0])
                        this.new_comment = {}
                        this.show_create_new = false
                        socket.emit('tell others', { type: 'add note', data: res.data})
                        this.$toast('Comment created successfully', 'success')
                    } else if(operation == 2){
                        // Edit
                        this.tj_comments_list = this.tj_comments_list.map(cc => {
                            if(cc.guid == res.data[0].guid){
                                cc = res.data[0]
                                cc.edit_mode = false
                                cc.original_content = res.data[0].content
                                this.$toast('Comment saved successfullt', 'success')
                            }
                            return cc
                        })
                        socket.emit('tell others', {type: 'edit note', data: res.data})
                    }
                }
            })
        },
        switch_edit_note(c){
            this.tj_comments_list = this.tj_comments_list.map(comment => {
                if(comment.guid == c.guid){
                    comment.edit_mode = !comment.edit_mode
                }
                return comment
            })
        },
        delete_note(n){
            this.$fire({
                text: this.$t("delete_this_comment"),
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    axios.post(this.$tetris_server +'/delete/tj/comment', {guid: n.guid})
                    .then(res => {
                        
                            this.tj_comments_list = this.tj_comments_list.filter(n => n.guid != res.data[1])
                            socket.emit('tell others', {type: 'delete note', data: res.data[1]})
                            this.$toast('Comment deleted', 'error')
                        
                    })
                } 
            });
        },
        cancel_edit_comment(c){

            this.tj_comments_list = this.tj_comments_list.map(co => {
                if(co.guid == c.guid){
                    co.edit_mode = false
                    co.content = co.original_content
                }
                return co
            })
        }
    },
}
</script>

<style>

</style>